<template>
  <div class="height1">
    <bread></bread>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker
                  v-model="searchForm.datepicker"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions0"
                  size="small"
                  style="margin-right: 10px"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="searchForm.keyWord"
                  type="text"
                  size="small"
                  placeholder="输入关键词搜索..."
                  @keyup.enter.native="SearchInputKeydown"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-alert
          title="如果发票信息有误或者更改，请及时与平台客服联系（0395-2563521），以免造成不必要损失！"
          type="warning"
          show-icon
        >
        </el-alert>
        <el-table
          :data="tableData"
          stripe
          height="calc(100% - 135px)"
          style="width: 100%"
          :header-cell-style="{ background: '#F5F7FC' }"
        >
          <el-table-column prop="organization.contactName" label="收票人姓名">
          </el-table-column>
          <el-table-column prop="money" label="金额"> </el-table-column>
          <el-table-column prop="invoiceType" label="发票类型">
            <template slot-scope="scope">
              <span v-if="scope.row.invoiceType == 0">增值税普通发票</span>
              <span v-if="scope.row.invoiceType == 1">增值税专用发票</span>
            </template>
          </el-table-column>
          <el-table-column prop="invoiceKind" label="发票种类">
            <template slot-scope="scope">
              <span v-if="scope.row.invoiceKind == 0">公司</span>
              <span v-if="scope.row.invoiceKind == 1">个人</span>
            </template>
          </el-table-column>
          <!--<el-table-column prop="name"
                                     label="支付状态">
                    </el-table-column>-->
          <el-table-column prop="state" label="发票">
            <template slot-scope="scope">
              <el-tag size="small" v-if="scope.row.state == 0" type="info">未开票</el-tag>
              <el-tag size="small" v-if="scope.row.state == 1" type="warning"
                >开票中</el-tag
              >
              <el-tag size="small" v-if="scope.row.state == 2" type="success"
                >已开票</el-tag
              >
              <el-tag size="small" v-if="scope.row.state == 3" type="danger"
                >开票失败</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="开票申请">
            <template slot-scope="scope">
              {{ scope.row.createTime | timefilters }}
            </template>
          </el-table-column>
          <el-table-column prop="processingTime" label="开票时间">
            <template slot-scope="scope">
              {{ scope.row.processingTime | timefilters }}
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <el-link type="primary" size="mini" @click="InvoiceDetail(scope.row)"
                >开票信息</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="ChangePage"
          :page-size="PageSize"
          :current-page="PageIndex"
          :total="DataTotal"
          layout="total, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
    <el-dialog :title="DialogTitle" :visible.sync="setDialog">
      <el-descriptions class="margin-top" :column="1" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            企业名称
          </template>
          {{ invoiceDetail.name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            税号
          </template>
          {{ invoiceDetail.invoiceNo }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            地址
          </template>
          {{ invoiceDetail.address }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            电话
          </template>
          {{ invoiceDetail.phone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            开户行
          </template>
          {{ invoiceDetail.bank }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            开户账号
          </template>
          {{ invoiceDetail.bankNo }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb";
import { Invoice } from "../../../components/HospitalDomain/Invoice";
export default {
  name: "InvoiceRecord",
  components: {
    bread,
  },
  data() {
    var invoice = new Invoice(this.TokenClient, this.Services.Authorization);
    return {
      Invoice: invoice,
      tableData: [],
      searchForm: {
        startTime: " ",
        endTime: " ",
        keyWord: " ",
        datepicker: "",
      },
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      DataTotal: 0,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      organizationId: JSON.parse(sessionStorage.getItem("userInfo")).organizationId,
      setDialog: false,
      DialogTitle: "新增发票信息",
      invoiceForm: {},
      formLabelWidth: "120px",
      invoiceDetail: {},
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    SearchInputKeydown(event) {
      if (event.keyCode == 13) {
      }
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex;
      this.getList();
    },
    search() {
      this.PageIndex = 1;
      this.getList();
    },
    getList() {
      var _this = this;
      var item = _this.searchForm;
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
      } else {
        item.startTime = "";
        item.endTime = "";
      }
      _this.Invoice.InvoiceList(
        _this.organizationId,
        item.startTime,
        item.endTime,
        item.keyWord,
        _this.PageIndex,
        function (data) {
          _this.tableData = data.data.results;
          _this.PageIndex = data.data.pageIndex;
          _this.PageSize = data.data.pageSize;
          _this.PageTotal = data.data.pageCount;
          _this.DataTotal = data.data.dataTotal;
        },
        function (err) {
          console.log(err);
        }
      );
    },
    InvoiceDetail(item) {
      var _this = this;
      _this.setDialog = true;
      _this.DialogTitle = "发票信息";
      _this.invoiceDetail = [];
      _this.Invoice.InvoiceDetails(
        item.invoiceInfoId,
        function (data) {
          _this.invoiceDetail = data.data;
        },
        function (err) {
          console.log(err);
        }
      );
    },
  },
};
</script>

<style scoped>
.dialog-form {
  width: 85%;
}
</style>
